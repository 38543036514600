import React from 'react'
import PropTypes from 'prop-types'

const ContactForm = ({ title }) => {
  return (
    <div>
      <section className="intro section section-nb">
        <div className="container">
          <h1 className="is-uppercase">CONTACT</h1>
          <div className="intro">
            Contacteer ons om te bekijken wat we voor uw onderneming kunnen
            betekenen. Offline? Online? Beide? We zijn van vele markten thuis.
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-four-fifths">
              <form
                name="contact"
                method="POST"
                action="/"
                data-netlify="true"
                netlify-honeypot="bot-field"
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="hidden">
                  <label>
                    Don’t fill this out if you're human:{' '}
                    <input name="bot-field" />
                  </label>
                </div>
                <div className="field">
                  <label className="label">Uw naam:</label>
                  <input className="input" type="text" name="name" />
                </div>
                <div className="field">
                  <label className="label">Uw e-mail:</label>
                  <input className="input" type="email" name="email" />
                </div>
                <div className="field">
                  <label className="label">Boodschap:</label>
                  <textarea className="textarea" name="message" />
                </div>
                <div className="field">
                  <button type="submit" className="button">
                    Verzenden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactForm

ContactForm.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contact: PropTypes.bool,
}
