import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'

const Contact = ({ data, path }) => {
  const { title } = data.site.siteMetadata
  return (
    <Layout path={path}>
      <Helmet>
        <title>{`Contact | ${title}`}</title>
        <meta name="description" content="Contact" />
        <meta name="og:title" content={`Contact | ${title}`} />
        <meta name="og:description" content="Contact" />
        {/*<script
          src="//code.tidio.co/radtwbsxhw59chsboiqakmk4wvpew4gz.js"
          async
        ></script>*/}
      </Helmet>
      <ContactForm title="Contact" />
    </Layout>
  )
}

export default Contact

export const queryContact = graphql`
  query contactQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
